export const addProducto = (state, producto) => {
    state.productos.push(producto)
}

export const editProducto = (state, {index, producto}) => {
    state.productos[index] = producto
}

export const eliminarProducto = (state, index) => {
    state.productos = state.productos.filter((r, pindex) => pindex !== index)
}

export const addCategoria = (state, nombre) => {
    state.categorias.push({
        nombre,
        partidas: []
    })
}

export const eliminarCategoria = (state, index) => {
    state.categorias.splice(index, 1)
}

export const addPartida = (state, { categoriaIndex, nombre }) => {
    state.categorias[categoriaIndex].partidas.push({
        nombre,
        productos: []
    })
}

export const loadCategorias = (state, categorias) => {
    state.categorias = categorias
}

export const resetCategorias = (state) => {
    state.categorias = []
}

export const resetPartidas = (state) => {
    state.partidas = []
}

export const eliminarPartida = (state, { categoriaIndex, pIndex }) => {
    state.categorias[categoriaIndex].partidas.splice(pIndex, 1)
}

export const addProductoPartida = (state, { categoriaIndex, index, producto }) => {
    state.categorias[categoriaIndex].partidas[index].productos.push(producto)
}

export const eliminarProductoPartida = (state, { categoriaIndex, index, productoIndex }) => {
    state.categorias[categoriaIndex].partidas[index].productos.splice(productoIndex, 1)
}

export const actualizarProductoPartida = (state, { categoriaIndex, partidaIndex, pIndex, producto }) => {
    state.categorias[categoriaIndex].partidas[partidaIndex].productos[pIndex] = producto
}

export const calcularTotalesProductos = (state, { indirecto, utilidad }) => {
    if (state.categorias.length > 0) {
        state.categorias.forEach((categoria) => {
            if (categoria.partidas.length > 0) {
                categoria.partidas.forEach((partida) => {
                    if (partida.productos.length > 0) {
                        partida.productos.forEach((producto) => {
                            const costo = producto.costoTotal
                            let calculo = costo * ((Number(indirecto)/100) + 1)
                            calculo = calculo * ((Number(utilidad)/100) + 1)
        
                            producto.total = calculo
                        })
                    }
                })
            }
        })
    }
}