<template>
    <TransitionRoot as="template" :show="open">
        <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="$emit('on:close')">
            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <div class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
                        <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                            <button type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none" @click="$emit('on:close')">
                                <span class="sr-only">Cerrar</span>
                                <font-awesome-icon icon="fa-solid fa-xmark" class="h-6 w-6"></font-awesome-icon>
                            </button>
                        </div>
                        <form @submit.prevent="onSubmit">
                            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                <div class="w-full flex justify-start text-gray-600 mb-3">
                                    <font-awesome-icon icon="fa-solid fa-box" class="h-11 w-11" />
                                </div>
                                <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">Vincular insumo</DialogTitle>
                                <div class="mt-5 relative">
                                    <label class="text-gray-800 text-sm font-bold leading-tight tracking-normal required">
                                        Elige los insumos que deseas vincular
                                    </label>
                                    <input v-model="busqueda" @input="buscarInsumo" class="mt-2 text-gray-600 focus:outline-none focus:border focus:border-xelpers-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border" placeholder="Buscar insumo" />
                                    <p class="text-red-700 font-light text-sm" v-if="errores.insumo">
                                        {{ errores.insumo }}
                                    </p>
                                    <div v-show="insumos.length > 0 && busqueda" class="absolute right-0 py-2 mt-2 bg-gray-50 rounded-md shadow-xl w-full">
                                        <div v-for="insumo in insumos" :key="insumo.id" @click.prevent="agregarInsumo(insumo)" class="block px-4 py-2 text-sm text-gray-600  hover:bg-gray-200 hover:text-gray-500 cursor-pointer z-40">
                                            {{ `${ insumo.nombre } (${ insumo.clave })` }}
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-2">
                                    <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                        <table class="min-w-full divide-y divide-gray-300">
                                            <thead class="bg-gray-50">
                                                <tr>
                                                    <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Nombre</th>
                                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Clave</th>
                                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Cantidad</th>
                                                    <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                        <span class="sr-only">Edit</span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody class="divide-y divide-gray-200 bg-white">
                                                <template v-if="vincularForm.insumos.length > 0">
                                                    <tr v-for="ins in vincularForm.insumos" :key="ins.id">
                                                        <td class=" py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{{ ins.nombre }}</td>
                                                        <td class=" px-3 py-4 text-sm text-gray-900">{{ ins.clave }}</td>
                                                        <td class=" px-3 py-4 text-sm text-gray-900">
                                                            <input v-model="ins.cantidad" id="nombre" class="mt-2 text-gray-600 focus:outline-none focus:border focus:border-xelpers-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border" placeholder="Cantidad" />
                                                        </td>
                                                        <td class="relative  py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                            <button @click="eliminarInsumo(ins)" type="button" class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"><font-awesome-icon icon="fa-solid fa-trash" /></button>
                                                        </td>
                                                    </tr>
                                                </template>
                                                <tr v-else>
                                                    <td class=" px-3 py-4 text-sm text-gray-900 text-center" colspan="4">No has agregado insumos.</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                    <button type="submit" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-xelpers-900 text-base font-medium text-white hover:bg-xelpers-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-xelpers-800 sm:ml-3 sm:w-auto sm:text-sm">Guardar</button>
                                    <button @click="$emit('on:close')" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm">Cancelar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
<script>
import useInsumos from '@/modules/insumos/composables/useInsumos';
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { useStore } from 'vuex';
import { useToast } from 'vue-toast-notification';
import { watch, ref } from 'vue';
import useProductos from '../composables/useProductos';

export default {
    props: {
        open: {
            type: Boolean,
            default: false
        },
        respuesta: {
            type: Object,
            required: false
        },
        regla: {
            type: Object,
            required: true
        }
    },
    emits: [ 'on:close', 'on:save' ],
    components: { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot },
    setup(props, { emit }) {
        const store = useStore()
        const vincularForm = ref({
            insumos: []
        })
        const { insumos, getInsumosByNombre } = useInsumos()
        const { vinculos } = useProductos()
        const busqueda = ref('')
        const errores = ref({})
        const $toast = useToast()

        watch(
            () => props.open, async(first, second) => {
                if (second === false) {
                    if (Object.keys(props.respuesta).length) {
                        vincularForm.value = {
                            insumos: props.respuesta.insumos
                        }
                    } else {
                        vincularForm.value = {
                            insumos: props.regla.insumos
                        }
                    }
                }
            }
        )

        const buscarInsumo = async() => {
            await getInsumosByNombre(busqueda.value)
        }

        const agregarInsumo = (insumo) => {
            vincularForm.value.insumos.push({
                id: insumo.id,
                nombre: insumo.nombre,
                clave: insumo.clave,
                cantidad: 1
            })

            busqueda.value = ''
            insumos.value = []
        }

        const eliminarInsumo = (insumo) => {
            vincularForm.value.insumos = vincularForm.value.insumos.filter(ins => ins.id !== insumo.id)
        }

        const onSubmit = async() => {
            store.commit('setLoading', true)

            $toast.success('Los cambios se han guardado exitosamente.', {
                position: 'bottom',
                duration: 3000
            })
            emit('on:save', vincularForm.value)

            store.commit('setLoading', false)
        }

        return {
            busqueda,
            errores,
            insumos,
            vinculos,
            vincularForm,

            agregarInsumo,
            buscarInsumo,
            eliminarInsumo,
            onSubmit
        }
    }
}
</script>
<style scoped>
    
</style>