import useProductos from "../composables/useProductos"

export const getProducto = async({ commit }, productoId) => {
    const { producto, getProducto } = useProductos()

    await getProducto(productoId)
    if (producto) {
        commit('setProducto', producto)
        return { ok: true }
    } else {
        return { ok: false }
    }
}

export const getReglas = async({ commit }, productoId) => {
    const { reglas, getReglas } = useProductos()

    await getReglas(productoId)
    commit('setReglas', reglas)
}