export const estadoActual = (state) => {
    return state.status
}

export const username = (state) => {
    return state.user ? state.user.name : ''
}

export const permisos = (state) => {
    return state.user.permisos
}