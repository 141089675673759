import xelpersApi from "@/api/xelpersApi"
import Swal from "sweetalert2"
import { ref } from "vue"
import { useToast } from "vue-toast-notification"

const useProductos = () => {
    const loading = ref(false)
    const productos = ref([])
    const producto = ref(null)
    const meta = ref([])
    const reglas = ref([])
    const regla = ref(null)
    const $toast = useToast()
    const vinculos = ref([])

    const getProductos = async(page = 1, busqueda = null) => {
        loading.value = true

        try {
            const { data } = await xelpersApi.get('/api/dashboard/productos', {
                params: {
                    page,
                    busqueda
                }
            })

            productos.value = data.data
            meta.value = data.meta
        } catch (error) {
            $toast.error('Ocurrió un error al obtener los productos.', {
                duration: 3000,
                position: 'bottom'
            })
        }

        loading.value = false
    }

    const crearProducto = async(productoForm) => {
        try {
            const { data } = await xelpersApi.post('/api/dashboard/productos', productoForm)
            
            $toast.success('El producto ha sido creado exitosamente.', {
                duration: 3000,
                position: 'bottom'
            })

            return { ok: true, producto: data.data }
        } catch (error) {
            $toast.error('Ocurrió un error al crear el producto.', {
                position: 'bottom',
                duration: 3000
            })

            return { ok: false, message: error.response.data.errors }
        }
    }

    const getProducto = async(productoId) => {
        if (!productoId) return false

        loading.value = true

        try {
            const { data } = await xelpersApi.get(`/api/dashboard/productos/${ productoId }`)
            producto.value = data.data
        } catch (error) {
            $toast.error('Ocurrió un error al obtener el producto.', {
                duration: 3000,
                position: 'bottom'
            })
        }

        loading.value = false
    }

    const getProductosByNombre = async(nombre = null) => {
        loading.value = true

        try {
            const { data } = await xelpersApi.get('/api/dashboard/productos-buscar', {
                params: { nombre }
            })
            productos.value = data.data
            meta.value = data.meta
        } catch (error) {
            $toast.error('Ocurrió un error al obtener los productos.', {
                position: 'bottom',
                duration: 3000
            })
        }

        loading.value = false
    }

    const getReglas = async(productoId) => {
        if (!productoId) return false

        loading.value = true

        try {
            const { data } = await xelpersApi.get(`/api/dashboard/productos/${ productoId }/reglas`)
            reglas.value = data.data
        } catch (error) {
            $toast.error('Ocurrió un error al obtener las reglas del producto.', {
                position: 'bottom',
                duration: 3000
            })
        }

        loading.value = false
    }

    const crearRegla = async(productoId, reglaForm) => {
        try {
            const { data } = await xelpersApi.post(`/api/dashboard/productos/${ productoId }/reglas`, reglaForm)

            $toast.success('La regla ha sido creada exitosamente.', {
                position: 'bottom',
                duration: 3000
            })
            return { ok: true, regla: data.data }
        } catch (error) {
            $toast.error('Ocurrió un error al crear la regla.', {
                position: 'bottom',
                duration: 3000
            })
            console.error(error)

            return { ok: false, message: error.response.data.errors }
        }
    }

    const getVinculosRespuesta = async(regla, respuesta = null) => {
        if (!respuesta) return false

        try {
            const { data } = await xelpersApi.get(`/api/dashboard/reglas/${ regla.id }/vinculos`, {
                params: { respuesta_id: respuesta.id }
            })

            vinculos.value = data.data
        } catch (error) {
            $toast.error('Ocurrió un error al obtener los vínculos.', {
                position: 'bottom',
                duration: 3000
            })
        }
    }

    const actualizarRespuesta = async(regla) => {
        try {
            const { data } = await xelpersApi.put(`/api/dashboard/reglas/${ regla.id }`, regla)

            $toast.success('La regla se ha actualizado exitosamente.', {
                position: 'bottom',
                duration: 3000
            })
            return { ok: true, regla: data.data }
        } catch (error) {
            $toast.error('Ocurrió un error al guardar la regla.', {
                position: 'bottom',
                duration: 3000
            })

            return { ok: false, message: error.response.data.errors }
        }
    }

    const reordenarReglas = async(productoId, reglas) => {
        try {
            const { data } = await xelpersApi.post(`/api/dashboard/productos/${ productoId }/ordenar`, { reglas })

            return { ok: true, reglas: data.data }
        } catch (error) {
            $toast.error('Ocurrió un error al ordenar las reglas.', {
                position: 'bottom',
                duration: 3000
            })

            return { ok: false }
        }
    }

    const getRegla = async(productoId, params = {}) => {
        try {
            const { data } = await xelpersApi.get(`/api/dashboard/productos/${ productoId }/regla`, {
                params
            })

            regla.value = data.data
        } catch (error) {
            $toast.error('Ocurrió un error al obtener la regla.', {
                position: 'bottom',
                duration: 3000
            })
        }
    }

    const cotizarRegla = async(productoId, datos) => {
        try {
            const { data } = await xelpersApi.post(`/api/dashboard/productos/${ productoId }/cotizar-regla`, datos)

            return { ok: true, datos: data }
        } catch (error) {
            $toast.error('Ocurrió un error al obtener los datos del cotizador.', {
                position: 'bottom',
                duration: 3000
            })

            return { ok: false }
        }
    }

    const actualizarProducto = async(producto, productoForm) => {
        try {
            const { data } = await xelpersApi.put(`/api/dashboard/productos/${ producto.id }`, productoForm)

            $toast.success('El producto ha sido actualizado exitosamente.', {
                duration: 3000,
                position: 'bottom'
            })
            return { ok: true, insumo: data.data }
        } catch (error) {
            $toast.error('Ocurrió un problema al actualizar el producto.', {
                duration: 3000,
                position: 'bottom'
            })

            console.log(error)
            return { ok: false, message: error.response.data.errors }
        }
    }

    const eliminarProducto = async(producto) => {
        const { isConfirmed } = await Swal.fire({
            title: '¿Quieres continuar?',
            text: 'Esto no podrá deshacerse.',
            icon: 'warning',
            showDenyButton: true,
            confirmButtonText: 'Eliminar producto',
            confirmButtonColor: '#dd6b55',
            denyButtonColor: '#c1c1c1'
        })

        if (isConfirmed) {
            new Swal({
                title: 'Espere, por favor',
                allowOutsideClick: false
            })
            Swal.showLoading()

            try {
                await xelpersApi.delete(`/api/dashboard/productos/${ producto.id }`)
                $toast.success('El producto ha sido eliminado exitosamente.', {
                    position: 'bottom',
                    duration: 3000
                })

                Swal.close()
                return { ok: true, eliminado: true }
            } catch (error) {
                $toast.error('Ocurrió un error al eliminar el producto.', {
                    position: 'bottom',
                    duration: 3000
                })

                Swal.close()
                return { ok: false }
            }
        } else {
            return { ok: true, eliminado: false }
        }
    }

    const clonarProduto = async(productoId) => {
        const { isConfirmed } = await Swal.fire({
            title: '¿Quieres continuar?',
            text: 'Estás a punto de clonar esta matriz.',
            icon: 'warning',
            showDenyButton: true,
            confirmButtonText: 'Clonar matriz',
            confirmButtonColor: '#dd6b55',
            denyButtonColor: '#c1c1c1'
        })

        if (isConfirmed) {
            try {
                const { data } = await xelpersApi.get(`/api/dashboard/productos/${ productoId }/clonar`)
    
                $toast.success('La matriz fue clonada exitosamente.', {
                    position: 'bottom',
                    duration: 3000
                })
                return { ok: true, clonado: true, producto: data.data }
            } catch (error) {
                $toast.error('Ocurrió un error al clonar la matriz.', {
                    position: 'bottom',
                    duration: 3000
                })
    
                return { ok: false }
            }
        } else {
            return { ok: true, clonado: false }
        }
    }

    return {
        loading,
        meta,
        producto,
        productos,
        regla,
        reglas,
        vinculos,

        actualizarProducto,
        actualizarRespuesta,
        cotizarRegla,
        crearProducto,
        crearRegla,
        eliminarProducto,
        getProducto,
        getProductos,
        getProductosByNombre,
        getRegla,
        getReglas,
        getVinculosRespuesta,
        reordenarReglas,
        clonarProduto
    }
}

export default useProductos