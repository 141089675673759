export default {
    name: 'ajustes',
    component: () => import('../layouts/AjustesLayout'),
    children: [
        {
            path: '',
            name: 'ajustes.index',
            component: () => import('../views/AjustesIndex')
        },
        {
            path: 'slides',
            name: 'ajustes.slides',
            component: () => import('../views/SlidesIndex')
        },
        {
            path: 'vehiculos',
            name: 'ajustes.vehiculos',
            component: () => import('../views/VehiculosPreguntas')
        },
        {
            path: 'medidas',
            name: 'ajustes.medidas',
            component: () => import('../views/UnidadesMedida')
        }
    ]
}