export const setProducto = (state, producto) => {
    state.producto = producto
}

export const setReglas = (state, reglas) => {
    state.reglas = reglas
}

export const setRegla = (state, regla) => {
    state.regla = regla
}

export const setAgregarRespuesta = (state, regla) => {
    regla.respuestas.push({
        respuesta: null,
        insumos: []
    })
}

export const eliminarRespuesta = (state, { rIndex, regla }) => {
    regla.respuestas = regla.respuestas.filter((r, index) => index !== rIndex)
}