import { createStore } from 'vuex'
import auth from '@/modules/auth/store'
import productos from '@/modules/productos/store'
import servicios from '@/modules/servicios/store'
import cotizaciones from '@/modules/cotizaciones/store'

export default createStore({
  state: {
    isLoading: false
  },
  getters: {
  },
  mutations: {
    setLoading(state, cargando) {
      state.isLoading = cargando
    }
  },
  actions: {
  },
  modules: {
    auth,
    cotizaciones,
    productos,
    servicios
  }
})
