export const productos = (state) => {
    return state.productos
}

export const partidas = (state) => {
    return state.partidas
}

export const categorias = (state) => {
    return state.categorias
}