<template>
    <TransitionRoot as="template" :show="open">
        <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="$emit('on:close')">
            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <div class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-top sm:max-w-3xl sm:w-full sm:p-6">
                        <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                            <button type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none" @click="$emit('on:close')">
                                <span class="sr-only">Cerrar</span>
                                <font-awesome-icon icon="fa-solid fa-xmark" class="h-6 w-6"></font-awesome-icon>
                            </button>
                        </div>
                        <form @submit.prevent="onSubmit">
                            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                <div class="w-full flex justify-start text-gray-600 mb-3">
                                    <font-awesome-icon icon="fa-solid fa-boxes-stacked" class="h-11 w-11" />
                                </div>
                                <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">Editar regla</DialogTitle>
                                <div class="mt-2 mb-5">
                                    <label for="nombre" class="text-gray-800 text-sm font-bold leading-tight tracking-normal required">Título</label>
                                    <input v-model="regla.titulo" id="nombre" class="mt-2 text-gray-600 focus:outline-none focus:border focus:border-xelpers-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border" placeholder="Título de la regla" />
                                    <p class="text-red-700 font-light text-sm" v-if="errores.titulo">
                                        {{ errores.titulo }}
                                    </p>
                                </div>
                                <div class="mt-2 mb-5">
                                    <label for="descripcion" class="text-gray-800 text-sm font-bold leading-tight tracking-normal required">Descripción</label>
                                    <textarea v-model="regla.descripcion" id="descripcion" class="mt-2 font-normal shadow-sm block w-full focus:outline-none focus:border-xelpers-700 sm:text-sm border border-gray-300 rounded h-20 p-3"></textarea>
                                    <p class="text-red-700 font-light text-sm" v-if="errores.descripcion">
                                        {{ errores.descripcion }}
                                    </p>
                                </div>
                                <div v-if="regla.tipo === 1 || regla.tipo === 4" class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                    <table class="min-w-full divide-y divide-gray-300">
                                        <thead class="bg-gray-50">
                                            <tr>
                                                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Respuesta</th>
                                                <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                    <span class="sr-only">Editar</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody class="divide-y divide-gray-200 bg-white">
                                            <template v-if="regla.respuestas.length > 0">
                                                <tr v-for="(respuesta, index) in regla.respuestas" :key="index">
                                                    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                        <input v-model="respuesta.respuesta" id="nombre" class="mt-2 text-gray-600 focus:outline-none focus:border focus:border-xelpers-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border" placeholder="Respuesta..." />
                                                    </td>
                                                    <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                        <button @click="$emit('on:vincularRespuesta', respuesta)" type="button" class="relative mr-2 inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-xelpers-500">
                                                            <font-awesome-icon icon="fa-solid fa-folder-tree" />
                                                            <div v-if="respuesta.insumos.length > 0" class="inline-flex absolute -top-2 -right-2 justify-center items-center w-5 h-5 text-[8px] font-light text-white bg-red-500 rounded-full border-1 border-white dark:border-gray-900">
                                                                {{ respuesta.insumos.length }}
                                                            </div>
                                                        </button>
                                                        <button @click="eliminarRespuesta(index, regla)" type="button" class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"><font-awesome-icon icon="fa-solid fa-trash" /></button>
                                                    </td>
                                                </tr>
                                            </template>
                                            <tr v-else>
                                                <td colspan="2" align="center" class="font-medium text-gray-900 text-sm py-3">No has agregado respuestas a esta regla.</td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colspan="2" class="text-right py-2">
                                                    <button @click="agregarRespuesta" type="button" class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-3">
                                                        <font-awesome-icon icon="fa-solid fa-plus" />
                                                        Agregar
                                                    </button>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                                <div v-else-if="regla.tipo === 2">
                                    <button @click="$emit('on:vincularInsumo', regla)" type="button" class="relative mr-2 inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-xelpers-500">
                                        <font-awesome-icon icon="fa-solid fa-folder-tree" />
                                        <div v-if="regla.insumos.length > 0" class="inline-flex absolute -top-2 -right-2 justify-center items-center w-5 h-5 text-[8px] font-light text-white bg-red-500 rounded-full border-1 border-white dark:border-gray-900">
                                            {{ regla.insumos.length }}
                                        </div>
                                    </button>
                                    Vincular insumos
                                </div>
                                <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                    <button type="submit" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-xelpers-900 text-base font-medium text-white hover:bg-xelpers-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-xelpers-800 sm:ml-3 sm:w-auto sm:text-sm">Guardar regla</button>
                                    <button @click="$emit('on:close')" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm">Cancelar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
<script>
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import useProductos from '../composables/useProductos'
import { useStore } from 'vuex'
import { ref, computed, watch } from 'vue';

export default {
    props: {
        open: {
            required: true,
            type: Boolean
        },
        reglaId: {
            required: true,
            type: Number
        }
    },
    emits: [ 'on:close', 'on:save', 'on:vincularRespuesta', 'on:vincularInsumo', 'on:enviarForm' ],
    components: { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot },
    setup(props, { emit }) {
        const reglaForm = ref({
            titulo: null,
            descripcion: null
        })
        const errores = ref({})
        const { actualizarRespuesta } = useProductos()
        const store = useStore()

        watch(
            () => props.open, async(first, second) => {
                if (!second) {
                    loadRegla()
                }
            }
        )

        const loadRegla = () => {
            store.getters['productos/getReglaById'](props.reglaId)
        }

        const regla = computed(() => {
            return store.getters['productos/getReglaById'](props.reglaId)
        })

        const agregarRespuesta = () => {
            store.commit('productos/setAgregarRespuesta', regla.value)
        }

        const eliminarRespuesta = (rIndex, regla) => {
            store.commit('productos/eliminarRespuesta', {rIndex, regla})
        }

        const onSubmit = async() => {
            store.commit('setLoading', true)
            await actualizarRespuesta(regla.value)
            emit('on:save')
            store.commit('setLoading', false)
        }

        return {
            errores,
            regla,
            reglaForm,

            agregarRespuesta,
            eliminarRespuesta,
            onSubmit
        }
    }
}
</script>
<style lang="">
    
</style>