import xelpersApi from "@/api/xelpersApi"

export const loginUser = async({ commit }, form) => {
    try {
        await xelpersApi.get('/sanctum/csrf-cookie')
    } catch (error) {
        return { ok: false, message: 'Hubo un problema. Favor de intentarlo nuevamente' }
    }

    try {
        const { data } = await xelpersApi.post('/login', form)
        const user = { name: data.name, email: data.email, id: data.id, permisos: data.permisos }

        commit('loginUser', user)

        return { ok: true, message: 'Has iniciado sesión con tu cuenta' }
    } catch (error) {
        return { ok: false, message: error.response.data.errors }        
    }
}

export const checkUser = async({ commit }) => {
    try {
        const { data } = await xelpersApi.get('/api/user')
        const { name, email, id, permisos } = data
        const user = {
            name,
            email,
            id,
            permisos
        }

        commit('loginUser', user)
        return { ok: true }
    } catch (error) {
        commit('logout')
        return { ok: false }
    }
}

export const logout = async({ commit }) => {
    try {
        await xelpersApi.get('/sanctum/csrf-cookie')
    } catch (error) {
        return { ok: false, message: 'Hubo un problema. Favor de intentarlo nuevamente.' }
    }

    try {
        await xelpersApi.post('/logout')
        commit('logout')

        return { ok: true, message: 'Has cerrado sesión' }
    } catch (error) {
        return { ok: false, message: error.response.data.errors }
    }
}