export const fotoElegida = (state) => {
    return state.fotoElegida
}

export const fotoVisible = (state) => {
    return state.fotoVisible
}

export const fotoIndex = (state) => {
    return state.fotoIndex
}