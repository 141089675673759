export const reglaEditar = (state) => {
    return state.regla
}

export const reglas = (state) => {
    return state.reglas
}

export const getReglaById = (state) => (id = '') => {
    return state.reglas.find(regla => regla.id === id)
}