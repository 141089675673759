<template>
    <div class="flex">
        <div class="max-w-lg bg-white overflow-hidden shadow rounded-lg flex flex-col divide-y divide-gray-200 mb-5">
            <div class="px-4 py-5 sm:p-6">
                <h3 class="text-gray-900 font-semibold text-md mb-2">{{ regla.titulo }}</h3>
                <span class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 mb-1">{{ getTipo(regla.tipo) }}</span>
                <p class="text-gray-700 font-light text-sm">{{ regla.descripcion }}</p>
            </div>
            <div v-if="editar" class="px-4 py-5">
                <div v-if="regla.tipo === 1 || regla.tipo === 4">
                    <table class="min-w-full divide-y divide-gray-300">
                        <thead class="bg-gray-50">
                            <tr>
                                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Respuesta</th>
                                <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                    <span class="sr-only">Editar</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody class="divide-y divide-gray-200 bg-white">
                            <template v-if="regla.respuestas.length > 0">
                                <tr v-for="(respuesta, index) in regla.respuestas" :key="index">
                                    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                        <input v-model="respuesta.respuesta" id="nombre" class="mt-2 text-gray-600 focus:outline-none focus:border focus:border-xelpers-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border" placeholder="Respuesta..." />
                                    </td>
                                    <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                        <button @click="vincularRespuesta(respuesta)" type="button" class="relative mr-2 inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-xelpers-500">
                                            <font-awesome-icon icon="fa-solid fa-folder-tree" />
                                            <div v-if="respuesta.insumos.length > 0" class="inline-flex absolute -top-2 -right-2 justify-center items-center w-5 h-5 text-[8px] font-light text-white bg-red-500 rounded-full border-1 border-white dark:border-gray-900">
                                                {{ respuesta.insumos.length }}
                                            </div>
                                        </button>
                                        <button @click="eliminarRespuesta(index, regla)" type="button" class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"><font-awesome-icon icon="fa-solid fa-trash" /></button>
                                    </td>
                                </tr>
                            </template>
                            <tr v-else>
                                <td colspan="2" align="center" class="font-medium text-gray-900 text-sm py-3">No has agregado respuestas a esta regla.</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colspan="2" class="text-right py-2">
                                    <button @click="agregarRespuesta" type="button" class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                        <font-awesome-icon icon="fa-solid fa-plus" />
                                        Agregar
                                    </button>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <div v-else-if="regla.tipo === 2">
                    <button @click="$emit('on:vincularInsumo', regla)" type="button" class="relative mr-2 inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-xelpers-500">
                        <font-awesome-icon icon="fa-solid fa-folder-tree" />
                        <div v-if="regla.insumos.length > 0" class="inline-flex absolute -top-2 -right-2 justify-center items-center w-5 h-5 text-[8px] font-light text-white bg-red-500 rounded-full border-1 border-white dark:border-gray-900">
                            {{ regla.insumos.length }}
                        </div>
                    </button>
                    Vincular insumos
                </div>
                <div class="mt-2">
                    <button @click="guardarRegla" type="button" class="mr-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-xelpers-600 hover:bg-xelpers-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-xelpers-500">Guardar</button>
                    <button @click="(editar = false)" type="button" class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-xelpers-500">Cancelar</button>
                </div>
            </div>
            <div class="px-4 py-4 sm:px-6 flex">
                <div class="flex-auto">
                    <a href="#" @click.prevent="(editar = true)" class="text-xs">Editar</a>
                </div>
                <div class="flex-auto">
                    <a href="#" class="text-xs">Eliminar</a>
                </div>
            </div>
        </div>
        <template v-if="regla.reglas && regla.reglas.length > 0">
            <regla-component v-for="subregla in regla.reglas"
            :key="subregla.id"
            :regla="subregla"
            class="ml-4"
            />
        </template>
        <ReglaEditar :reglaId="regla.id" :open="editar" @on:close="editar = false" @on:vincularRespuesta="vincularRespuesta" @on:vincularInsumo="vincularInsumo" />
        <VinculosModal :open="vincularModal" :regla="regla" :respuesta="respuestaVincular" @on:close="vincularModal = false" @on:save="vinculoGuardado" />
    </div>
</template>
<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import useProductos from '../composables/useProductos'
import ReglaEditar from './ReglaEditar.vue'
import VinculosModal from './VinculosModal'

export default {
    props: {
        regla: {
            type: Object,
            required: true
        }
    },
    components: { VinculosModal, ReglaEditar },
    emits: [ 'on:agregarRespuesta', 'on:eliminarRespuesta', 'on:guardarVinculo', 'on:vincularInsumo' ],
    setup(props) {
        const editar = ref(false)
        const { actualizarRespuesta } = useProductos()
        const store = useStore()
        const vincularModal = ref(false)
        const respuestaVincular = ref({})
        const reglaVincular = ref({})

        const getTipo = (tipo) => {
            switch (tipo) {
                case 1:
                    return 'Una opción'
                case 2:
                    return 'Número'
                case 3:
                    return 'Texto'
                case 4:
                    return 'Múltiples opciones'
                default:
                    return 'Ninguna'
            }
        }

        const eliminarRespuesta = (rIndex, regla) => {
            store.commit('productos/eliminarRespuesta', {rIndex, regla})
        }

        const vinculoGuardado = (vincularForm) => {
            if (Object.keys(respuestaVincular.value).length > 0) {
                respuestaVincular.value.insumos = vincularForm.insumos
            } else {
                reglaVincular.value.insumos = vincularForm.insumos
            }

            vincularModal.value = false
        }

        const guardarRegla = async() => {
            store.commit('setLoading', true)
            const resp = await actualizarRespuesta(props.regla)
            if (resp.ok) {
                editar.value = false
            }
            store.commit('setLoading', false)
        }

        const agregarRespuesta = () => {
            store.commit('productos/setAgregarRespuesta', props.regla)
        }

        const vincularRespuesta = (respuesta) => {
            respuestaVincular.value = respuesta
            vincularModal.value = true
        }

        const vincularInsumo = () => {
            respuestaVincular.value = {}
            vincularModal.value = true
        }

        return {
            editar,
            reglaModificar: props.regla,
            respuestaVincular,
            vincularModal,

            agregarRespuesta,
            eliminarRespuesta,
            getTipo,
            guardarRegla,
            vincularInsumo,
            vincularRespuesta,
            vinculoGuardado
        }
    }
}
</script>
<style scoped>
    
</style>