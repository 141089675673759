import xelpersApi from "@/api/xelpersApi"
import Swal from "sweetalert2"
import { ref } from "vue"
import { useToast } from "vue-toast-notification"

const useInsumos = () => {
    const loading = ref(false)
    const insumos = ref([])
    const meta = ref([])
    const $toast = useToast()

    const getInsumos = async(page = 1, busqueda = null) => {
        if (page <= 0) page = 1

        loading.value = true

        try {
            const { data } = await xelpersApi.get('/api/dashboard/insumos', {
                params: { page, busqueda }
            })

            insumos.value = data.data
            meta.value = data.meta
        } catch (error) {
            $toast.error('Ocurrió un error al obtener los insumos.', {
                position: 'bottom',
                duration: 3000
            })
        }

        loading.value = false
    }

    const crearInsumo = async(insumoForm) => {
        try {
            const { data } = await xelpersApi.post('/api/dashboard/insumos', insumoForm)

            $toast.success('El insumo ha sido creado exitosamente.', {
                duration: 3000,
                position: 'bottom'
            })
            return { ok: true, insumo: data.data }
        } catch (error) {
            $toast.error('Ocurrió un problema al publicar el insumo.', {
                duration: 3000,
                position: 'bottom'
            })

            return { ok: false, message: error.response.data.errors }
        }
    }

    const actualizarInsumo = async(insumo, insumoForm) => {
        try {
            const { data } = await xelpersApi.put(`/api/dashboard/insumos/${ insumo.id }`, insumoForm)

            $toast.success('El insumo ha sido actualizado exitosamente.', {
                duration: 3000,
                position: 'bottom'
            })
            return { ok: true, insumo: data.data }
        } catch (error) {
            $toast.error('Ocurrió un problema al actualizar el insumo.', {
                duration: 3000,
                position: 'bottom'
            })

            console.log(error)
            return { ok: false, message: error.response.data.errors }
        }
    }

    const getInsumosByNombre = async(nombre = null) => {
        loading.value = true

        try {
            const { data } = await xelpersApi.get('/api/dashboard/insumos-buscar', {
                params: { nombre }
            })
            insumos.value = data.data
            meta.value = data.meta
        } catch (error) {
            $toast.error('Ocurrió un error al obtener los insumos.', {
                position: 'bottom',
                duration: 3000
            })
        }

        loading.value = false
    }

    const eliminarInsumo = async(insumo) => {
        const { isConfirmed } = await Swal.fire({
            title: '¿Quieres continuar?',
            text: 'Esto no podrá deshacerse.',
            icon: 'warning',
            showDenyButton: true,
            confirmButtonText: 'Eliminar insumo',
            confirmButtonColor: '#dd6b55',
            denyButtonColor: '#c1c1c1'
        })

        if (isConfirmed) {
            new Swal({
                title: 'Espere, por favor',
                allowOutsideClick: false
            })
            Swal.showLoading()

            try {
                await xelpersApi.delete(`/api/dashboard/insumos/${ insumo.id }`)
                $toast.success('El insumo ha sido eliminado exitosamente.', {
                    position: 'bottom',
                    duration: 3000
                })

                Swal.close()
                return { ok: true, eliminado: true }
            } catch (error) {
                $toast.error('Ocurrió un error al eliminar el insumo.', {
                    position: 'bottom',
                    duration: 3000
                })

                Swal.close()
                return { ok: false }
            }
        } else {
            return { ok: true, eliminado: false }
        }
    }

    const vincularProveedor = async(vincularForm) => {
        try {
            const { data } = await xelpersApi.post('/api/dashboard/vincular-proveedor-insumo', vincularForm)

            $toast.success('La vinculación fue realizada exitosamente.', {
                position: 'bottom',
                duration: 3000
            })

            return { ok: true, insumo: data.data }
        } catch (error) {
            $toast.error('Ocurrió un error al vincular el insumo.', {
                position: 'bottom',
                duration: 3000
            })

            return { ok: false }
        }
    }

    const clonarInsumo = async(insumoId) => {
        const { isConfirmed } = await Swal.fire({
            title: '¿Quieres continuar?',
            text: 'Estás a punto de clonar este insumo.',
            icon: 'warning',
            showDenyButton: true,
            confirmButtonText: 'Clonar insumo',
            confirmButtonColor: '#dd6b55',
            denyButtonColor: '#c1c1c1'
        })

        if (isConfirmed) {
            try {
                const { data } = await xelpersApi.get(`/api/dashboard/insumos/${ insumoId }/clonar`)
    
                $toast.success('El insumo fue clonado exitosamente.', {
                    position: 'bottom',
                    duration: 3000
                })
                return { ok: true, clonado: true, insumo: data.data }
            } catch (error) {
                $toast.error('Ocurrió un error al clonar el insumo.', {
                    position: 'bottom',
                    duration: 3000
                })
    
                return { ok: false }
            }
        } else {
            return { ok: true, clonado: false }
        }
    }

    return {
        insumos,
        loading,
        meta,

        actualizarInsumo,
        crearInsumo,
        eliminarInsumo,
        getInsumos,
        getInsumosByNombre,
        vincularProveedor,
        clonarInsumo
    }
}

export default useInsumos