import ajustesRouter from '@/modules/ajustes/router'
import estaAutenticado from '@/modules/auth/router/auth-guard';

export default {
    name: 'dashboard',
    component: () => import('@/modules/dashboard/layouts/DashboardLayout'),
    children: [
        {
            path: '',
            name: 'inicio',
            beforeEnter: [estaAutenticado],
            component: () => import('@/modules/dashboard/views/PanelView')
        },
        {
            path: 'ajustes',
            beforeEnter: [estaAutenticado],
            ...ajustesRouter
        },
        {
            path: 'categorias',
            name: 'categorias.index',
            beforeEnter: [estaAutenticado],
            component: () => import('@/modules/categorias/views/CategoriasIndex')
        },
        {
            path: 'productos',
            name: 'productos.index',
            beforeEnter: [estaAutenticado],
            component: () => import('@/modules/productos/views/ProductosIndex')
        },
        {
            path: 'productos/:id/reglas',
            name: 'productos.reglas',
            beforeEnter: [estaAutenticado],
            component: () => import('@/modules/productos/views/ReglasView')
        },
        {
            path: 'insumos',
            name: 'insumos.index',
            beforeEnter: [estaAutenticado],
            component: () => import('@/modules/insumos/views/InsumosIndex')
        },
        {
            path: 'almacenes',
            name: 'almacenes.index',
            beforeEnter: [estaAutenticado],
            component: () => import('@/modules/almacenes/views/AlmacenesIndex')
        },
        {
            path: 'almacenes/:id',
            name: 'almacenes.ver',
            beforeEnter: [estaAutenticado],
            component: () => import('@/modules/almacenes/views/AlmacenVer')
        },
        {
            path: 'xelpers',
            name: 'xelpers.index',
            beforeEnter: [estaAutenticado],
            component: () => import('@/modules/xelpers/views/XelpersIndex')
        },
        {
            path: 'clientes',
            name: 'clientes.index',
            beforeEnter: [estaAutenticado],
            component: () => import('@/modules/clientes/views/ClientesIndex'),
        },
        {
            path: 'clientes/:id',
            name: 'clientes.ver',
            beforeEnter: [estaAutenticado],
            component: () => import('@/modules/clientes/views/ClienteShow')
        },
        {
            path: 'clientes/:id/direcciones',
            name: 'clientes.direcciones',
            beforeEnter: [estaAutenticado],
            component: () => import('@/modules/clientes/views/DireccionesIndex')
        },
        {
            path: 'vehiculos',
            name: 'vehiculos.index',
            beforeEnter: [estaAutenticado],
            component: () => import('@/modules/vehiculos/views/VehiculosIndex')
        },
        {
            path: 'vehiculos/:id',
            name: 'vehiculos.cuestionarios',
            beforeEnter: [estaAutenticado],
            component: () => import('@/modules/vehiculos/views/VehiculoCuestionarios')
        },
        {
            path: 'cotizaciones',
            name: 'cotizaciones.index',
            beforeEnter: [estaAutenticado],
            component: () => import('@/modules/cotizaciones/views/CotizacionesIndex')
        },
        {
            path: 'cotizaciones/nuevo',
            name: 'cotizaciones.nuevo',
            beforeEnter: [estaAutenticado],
            component: () => import('@/modules/cotizaciones/views/CotizacionForm')
        },
        {
            path: 'cotizaciones/:id',
            name: 'cotizaciones.ver',
            beforeEnter: [estaAutenticado],
            component: () => import('@/modules/cotizaciones/views/CotizacionView')
        },
        {
            path: 'cotizaciones/:id/editar',
            name: 'cotizaciones.editar',
            beforeEnter: [estaAutenticado],
            component: () => import('@/modules/cotizaciones/views/CotizacionForm')
        },
        {
            path: 'servicios',
            name: 'servicios.index',
            beforeEnter: [estaAutenticado],
            component: () => import('@/modules/servicios/views/ServiciosIndex')
        },
        {
            path: 'servicios/:id',
            name: 'servicios.ver',
            beforeEnter: [estaAutenticado],
            component: () => import('@/modules/servicios/views/ServicioView')
        },
        {
            path: 'servicios/cancelaciones',
            name: 'servicios.cancelaciones',
            beforeEnter: [estaAutenticado],
            component: () => import('@/modules/servicios/views/ServiciosCancelaciones')
        },
        {
            path: 'compras',
            name: 'compras.index',
            beforeEnter: [estaAutenticado],
            component: () => import('@/modules/compras/views/ComprasIndex')
        },
        {
            path: 'compras/cancelaciones',
            name: 'compras.cancelaciones',
            beforeEnter: [estaAutenticado],
            component: () => import('@/modules/compras/views/ComprasCancelaciones')
        },
        {
            path: 'compras/nuevo',
            name: 'compras.nuevo',
            beforeEnter: [estaAutenticado],
            component: () => import('@/modules/compras/views/ComprasNuevo')
        },
        {
            path: 'compras/:id',
            name: 'compras.ver',
            beforeEnter: [estaAutenticado],
            component: () => import('@/modules/compras/views/ComprasVer')
        },
        {
            path: 'gastos',
            name: 'gastos.index',
            beforeEnter: [estaAutenticado],
            component: () => import('@/modules/gastos/views/GastosIndex')
        },
        {
            path: 'gastos/cancelaciones',
            name: 'gastos.cancelaciones',
            beforeEnter: [estaAutenticado],
            component: () => import('@/modules/gastos/views/GastosCancelaciones')
        },
        {
            path: 'bancos',
            name: 'bancos.index',
            beforeEnter: [estaAutenticado],
            component: () => import('@/modules/bancos/views/BancosIndex')
        },
        {
            path: 'bancos/:id',
            name: 'bancos.ver',
            beforeEnter: [estaAutenticado],
            component: () => import('@/modules/bancos/views/BancosVer')
        },
        {
            path: 'proveedores',
            name: 'proveedores.index',
            beforeEnter: [estaAutenticado],
            component: () => import('@/modules/proveedores/views/ProveedoresIndex'),
        },
        {
            path: 'roles',
            name: 'roles.index',
            beforeEnter: [estaAutenticado],
            component: () => import('@/modules/usuarios/views/RolesIndex')
        },
        {
            path: 'usuarios',
            name: 'usuarios.index',
            beforeEnter: [estaAutenticado],
            component: () => import('@/modules/usuarios/views/UsuariosIndex')
        },
        {
            path: 'estimaciones',
            name: 'estimaciones.index',
            beforeEnter: [estaAutenticado],
            component: () => import('@/modules/estimaciones/views/EstimacionesIndex')
        }
    ]
}