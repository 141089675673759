import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

import ReglaComponent from '@/modules/productos/components/ReglaComponent'
import VueGoogleMaps from 'vue-google-maps-community-fork'
import VCalendar from 'v-calendar'

import './assets/css/tailwind.css'
import 'vue-toast-notification/dist/theme-sugar.css'
import 'v-calendar/style.css';

library.add(fas, far)

createApp(App).use(store).use(router).use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyAYDpx11SW4r_mjwu_tLzJ9zCw_kw_1y3M',
        libraries: 'places'
    }
}).use(VCalendar, {}).component('font-awesome-icon', FontAwesomeIcon).component('regla-component', ReglaComponent).mount('#app')
