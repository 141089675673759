import store from '@/store'

const estaAutenticado = async(_, __, next) => {
    const { ok } = await store.dispatch('auth/checkUser')
    if (ok) {
        next()
    } else {
        next({ name: 'login' })
    }
}

export default estaAutenticado